<template>
    <transition v-show="content && isPopupVisible && !isPopupClosed"
        mode="in-out"
        enter-active-class="transition-opacity duration-900 ease-in"
        enter-from-class="opacity-0 translate-x-1"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity duration-900 ease-out"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0 translate-x-1">
        <div v-if="content !== undefined && isPopupVisible"
            class="fixed inset-0 z-50 flex items-center justify-center rounded-[4px] bg-black/50">
            <div class="relative h-1/2 w-3/4 rounded-[4px]
                bg-[color:var(--background-color)] text-[color:var(--text-color)]
                [filter:drop-shadow(-3px_7px_7px_rgb(0_0_0/80%))] lg:w-1/2"
                :style="{
                    '--background-color': backgroundColor,
                    '--text-color': fontColor
                }">
                <button type="button"
                    class="absolute -top-5 right-4 z-20 size-10 rounded-full
                    bg-red-500 text-white md:-right-2 md:-top-2 md:size-8"
                    @keypress.esc="close"
                    @click="close">
                    <v-icon icon="close" />
                </button>

                <component :is="content.content.component"
                    :id="content.content.gtm_id"
                    :name="content.name"
                    :blok="content.content"
                    @close="close" />
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
const isPopupClosed = ref(false);
const isPopupVisible = ref(false);

const cookieGtmId = ref('');

const gtmCookies = useCookie<Array<{ id: string }>>('cc_popup_items', {
    maxAge: 60 * 60 * 24 * 30, // 30 days
});

if (!Array.isArray(gtmCookies.value)) {
    gtmCookies.value = [];
}

const content = ref();

// Fetch the popups from Storyblok and filter the global GTM popups.
// This is used to get the content of the popup when the GTM trigger is fired.
// GTM popup is fltered by the gtm_id in the handleCustomEvent function.
const popups = useStoryblokStories('pop-ups-content', {
    /* eslint-disable @typescript-eslint/naming-convention */
    starts_with: 'global/gtm-popups',
    is_startpage: false,
    version: 'published',
});

/**
 * Handles a custom event to manage the visibility and content of a popup.
 *
 * @param {CustomEvent} event - The custom event containing the popup ID.
 *
 * The function performs the following steps:
 * 1. Extracts the popup ID from the event details and assigns it to `cookieGtmId`.
 * 2. Checks if the popup ID is already present in the `gtmCookies` array.
 * 3. If the popup ID is not present in the `gtmCookies` array, assigns the popup content to `content`.
 * 4. Sets `isPopupVisible` to true.
 */

const handleCustomEvent = (event: CustomEvent) => {
    const gtmId = event.detail.popupId as string;
    cookieGtmId.value = gtmId;

    if (gtmCookies.value.some((cookie) => cookie.id === cookieGtmId.value)) {
        isPopupVisible.value = false;
    } else {
        content.value = popups.value.find((story) => story.content.gtm_id === gtmId);
        isPopupVisible.value = true;
    }
};

/**
 * Closes the popup by setting `isPopupVisible` to false.
 * Adds a new cookie with the current `cookieGtmId` to the `gtmCookies` array.
 * If `gtmCookies` is not defined, initializes it with the new cookie.
 */
function close() {
    isPopupVisible.value = false;

    const newCookie = { id: cookieGtmId.value };
    if (gtmCookies.value) {
        gtmCookies.value.push(newCookie);
    } else {
        gtmCookies.value = [newCookie];
    }
}

/** Color management
 *  Colors are taken from the enabled component's information.
 */
const backgroundColor = computed(() => content.value?.content?.background_color?.color || '#FFF');
const fontColor = computed(() => content.value?.content?.font_color?.color || '#000');

/** Mounting
 * When the component is mounted, we are listening to the custom event
 * that is triggered from GTM.
 * This is used to show the popup in handleTrigger function when the GTM trigger is fired.
 * The event listener is removed when the component is unmounted.
 */
onMounted(() => {
    setTimeout(() => {
        window.addEventListener('popupTriggerEvent', handleCustomEvent as EventListener);
    }, 300, { immediate: true });
});

onBeforeUnmount(() => {
    window.removeEventListener('popupTriggerEvent', handleCustomEvent as EventListener);
});
</script>
